<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi taloyhtiö</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan kiinteistöä/taloyhtiötä..."
      class="full-page-loader"
    ></full-page-loader>

    <condominium-form save-btn-text="Luo" @sendcondominium="submit"></condominium-form>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "@/services/apiAgent.js";
import CondominiumForm from "../../components/Condominium/CondominiumForm";
import mixins from "../../mixins/mixins";
import { mapMutations, mapState } from "vuex";
import FullPageLoader from "@/components/FullPageLoader";

export default {
  title: "Uusi taloyhtiö",

  components: {
    CondominiumForm,
    FullPageLoader,
  },

  mixins: [mixins],

  computed: {
    ...mapState("condominium", ["currentCondominium", "loading"]),
  },

  created() {
    this.setLoading(true);
    this.setDefaultCondominium();
    this.setLoading(false);
  },

  methods: {
    ...mapMutations("condominium", ["setDefaultCondominium", "setLoading"]),

    async submit() {
      try {
        let condominium = this.currentCondominium;

        await apiAgent({
          method: "POST",
          url: "/api/v1/condominium/create",
          data: condominium,
        });

        this.$router.push("/condominiums");
        this.showPopup("Uusi taloyhtiö luotu", "success");
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped></style>
